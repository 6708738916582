module.exports ={
  "propertyService": "https://p2jny1bvva.execute-api.us-east-1.amazonaws.com/daniel",
  "domusoApiService": "https://dgeug8los1.execute-api.us-east-1.amazonaws.com/daniel",
  "appService": "https://domusograilsweb-daniel.devmuso.com",
  "reservationService": "https://xsksepva32.execute-api.us-east-1.amazonaws.com/daniel",
  "userPoolId": "us-east-1_kPRU6HBdW",
  "authService": "https://vw45aibq2k.execute-api.us-east-1.amazonaws.com/daniel",
  "userPoolWebClientId": "22uckdipuae3ktona5v6ttgv9s",
  "emailLinkBase": "https://domusograilsweb-daniel.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-dev@devmuso.com",
  "residentWebUI": "https://resident-daniel.devmuso.com",
  "maintenanceUrl": "https://vw45aibq2k.execute-api.us-east-1.amazonaws.com/daniel/maintenance",
  "env": "daniel"
}